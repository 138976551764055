<template>
  <div class="q-loader-noicon">
    <q-skeleton
      type="text"
      width="160px"
      height="40px"
      v-if="showLocationDialogLoader"
    />
    <q-list class="locationList skeleton">
      <q-item tag="label" v-for="n in skeletonSize" :key="n">
        <q-item-section top>
          <div class="row wrap items-center">
            <div class="col-12 col-md-12 hlocationListDetail">
              <div class="row q-mb-sm">
                <div class="col-xs-9 title">
                  <q-skeleton type="text" width="70%" height="30px" />
                </div>
                <div class="col-xs-3 text-right">
                  <q-skeleton type="text" width="100%" height="30px" />
                </div>
              </div>

              <q-item-label class="q-mb-sm" caption>
                <q-skeleton type="text" width="100%" />
                <q-skeleton type="text" width="40%" />
                <q-skeleton type="text" width="40%" />
              </q-item-label>
              <q-item-label class="hlocationListIcon">
                <div class="row q-col-gutter-sm">
                  <div class="col-3">
                    <q-skeleton type="circle" width="30px" height="30px" />
                  </div>
                  <div class="col-3">
                    <q-skeleton type="circle" width="30px" height="30px" />
                  </div>
                  <div class="col-3">
                    <q-skeleton type="circle" width="30px" height="30px" />
                  </div>
                  <div class="col-3">
                    <q-skeleton type="circle" width="30px" height="30px" />
                  </div>
                </div>
              </q-item-label>
              <div class="row q-col-gutter-md q-mt-xs q-mb-sm">
                <div class="col-xs-5">
                  <q-skeleton type="rect" width="100%" />
                </div>
                <div class="col-xs-5">
                  <q-skeleton type="rect" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
// collectionSize
export default {
  name: 'CollectionSkeleton',

  props: {
    skeletonSize: {
      type: Number,
      default: 6,
    },
    showLocationDialogLoader: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
.locationList.skeleton.q-list .q-item__section {
  padding-bottom: 0;
}
</style>
